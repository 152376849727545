<template lang="">
  <div>
    <div class="mb-8">
      <div class="titulo-pagina">
        {{ $t('modulos.colaborador.titulos.ausencia') }}
      </div>
      <v-chip
        class="mr-1"
        small
      >
        Colaborador: {{ nomeColaborador }}
      </v-chip>
      <spam
        class="quantidade"
      >
        {{
          $tc('componentes.cabecalho_pagina.registros', tabela.quantidadeItens, {
            count: tabela.quantidadeItens,
          })
        }}
      </spam>
      <div class="d-flex w-100 justify-space-between">
        <botao-padrao
          v-if="podeExcluir"
          outlined
          color="danger"
          @click="excluirRegistros"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
        <span />
        <botao-padrao
          class="my-2"
          @click="() => abrirForm()"
        >
          <v-icon class="mr-2">
            $accountClockOutline
          </v-icon>
          {{ $t('modulos.colaborador.botoes.nova_ausencia') }}
        </botao-padrao>
      </div>
      <tabela-padrao-prime-vue
        ref="tabela"
        v-model="tabela.selecionados"
        class="mt-2"
        :colunas="tabela.colunas"
        :dados="tabela.dados"
        :mostrar-acoes="true"
        filtros-com-colunas
        :quantidade-itens="tabela.quantidadeItens"
        :por-pagina="tabela.porPagina"
        :quantidade-paginas="tabela.quantidadePaginas"
        :pagina="tabela.paginaAtual"
        @paginar="listarRegistros"
      >
        <template v-slot:acoes="{ slotProps }">
          <dropdown-padrao
            text
            color="secondary"
          >
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <div>
              <dropdown-padrao-item
                text
                color="secondary"
                @click="abrirForm(slotProps.data)"
              >
                {{ $t('geral.botoes.editar') }}
              </dropdown-padrao-item>
            </div>
          </dropdown-padrao>
        </template>
      </tabela-padrao-prime-vue>
      <div class="d-flex align-center justify-end">
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="voltar"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.voltar') }}
        </botao-padrao>
      </div>
      <AusenciaForm
        ref="modal-form"
        :colaborador-id="id"
        @atualizarListagem="() => listarRegistros()"
      />
    </div>
  </div>
</template>
<script>
import ColaboradorAusenciaService from '@common/services/cadastros/ColaboradorAusenciaService';
import ColaboradorService from '@common/services/cadastros/ColaboradorService';
import AusenciaForm from './components/modais/AusenciaForm.vue';
import helpers from '@common/utils/helpers.js';

export default {
  components: { AusenciaForm },
  props: ['id'],
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'dataInicial',
            text: this.$t('modulos.colaborador.tabela.data_inicial'),
            sortable: true,
            formatter: (v) => (v ? helpers.formatarDataBr(v) : ''),
          },
          {
            value: 'dataFinal',
            text: this.$t('modulos.colaborador.tabela.data_final'),
            sortable: true,
            formatter: (v) => (v ? helpers.formatarDataBr(v) : ''),
          },
          {
            value: 'possuiAtestado',
            text: this.$t('modulos.colaborador.tabela.possuiAtestado'),
            sortable: true,
            formatter: (v) =>
              helpers.BoleanoEnum.find((el) => el.value === !!v).text,
          },
          {
            value: 'motivo',
            text: this.$t('modulos.colaborador.tabela.motivo'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      nomeColaborador: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  mounted() {
    this.listarRegistros();
    this.buscarColaborador();
  },
  methods: {
    listarRegistros(
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ColaboradorAusenciaService.listar({ colaboradorId: this.id })
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => this.$store.dispatch('Layout/terminarCarregamento'));
    },
    buscarColaborador() {
      ColaboradorService.buscar(this.id).then((res) => {
        this.nomeColaborador = res.data.nome;
      });
    },
    voltar(){
        this.$router.push({ name: 'colaborador' });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        const promises = [];

        this.tabela.selecionados.forEach((item) => {
          promises.push(ColaboradorAusenciaService.excluir(item.id));
        });

        Promise.all(promises)
          .then(() => {
            this.listarRegistros();
            this.toastSucesso(
              this.$t(`modulos.colaborador.exclusao_ausencia_sucesso`)
            );
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
            this.tabela.selecionados = [];
          });
      });
    },
    abrirForm(ausencia = null) {
      if (this.$refs['modal-form'])
        this.$refs['modal-form'].abrirModal(ausencia);
    },
  },
};
</script>
<style lang="scss">
.quantidade {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
</style>

