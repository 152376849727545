<template lang="">
  <modal-padrao
    ref="modal-nova-ausencia"
    :max-width="$vuetify.breakpoint.name == 'xs' ? '90%' : '60%'"
    :gerarScroll="false"
    :titulo="$t('modulos.colaborador.botoes.nova_ausencia')"
    :titulo-ok="$t('geral.botoes.salvar')"
    :titulo-cancelar="$t('geral.botoes.voltar')"
    @ok="confirmarSalvar"
  >
    <v-form ref="form" class="row py-2">
      <input-date
        v-model="form.dataInicial"
        class="col-12 col-md-6"
        :label="$t('modulos.colaborador.formulario.data_inicial')"
        obrigatorio
      />
      <input-date
        v-model="form.dataFinal"
        class="col-12 col-md-6"
        :label="$t('modulos.colaborador.formulario.data_final')"
        obrigatorio
        :data-referencia="form.dataInicial"
      />
      <input-select
        v-model="form.possuiAtestado"
        class="col-12 col-md-6"
        :label="$t('modulos.colaborador.formulario.possuiAtestado')"
        :options="opcoes.booleano"
      />
      <input-text
        v-model="form.motivo"
        class="col-12 col-md-6"
        :label="$t('modulos.colaborador.formulario.motivo')"
        :max="200"
        obrigatorio
      />
      <input-textarea
        v-model="form.observacao"
        class="col-12 col-md-12"
        :label="$t('modulos.colaborador.formulario.observacao')"
        :max="500"
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import ColaboradorAusenciaService from '@common/services/cadastros/ColaboradorAusenciaService';
import helpers from '@common/utils/helpers.js';
export default {
  props: {
    colaboradorId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      opcoes: {
        booleano: helpers.BoleanoEnum,
      },
      form: {},
      edicao: false,
    };
  },
  computed: {
    EhTelaPequena() {
      return ['sm', 'xs'].includes(this.$vuetify.breakpoint.name);
    },
  },
  methods: {
    abrirModal: function (ausencia) {
      this.iniciarForm();
      this.$refs['modal-nova-ausencia'].abrirModal();
      if (ausencia) this.buscar(ausencia.id);
    },
    iniciarForm() {
      this.form = {
        id: null,
        dataInicial: null,
        dataFinal: null,
        possuiAtestado: false,
        motivo: null,
        observacao: null,
        colaboradorId: this.colaboradorId,
      };
      this.$refs.form?.resetValidation();
    },
    confirmarSalvar() {
      if (!this.$refs.form?.validate()) return;
      this.salvar();
    },
    buscar(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ColaboradorAusenciaService.buscar(id)
        .then((res) => {
          this.form = { ...res.data, colaboradorId: this.colaboradorId };
          this.edicao = true;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    salvar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      if (!this.form.observacao) this.form.observacao = '';
      ColaboradorAusenciaService.salvar(this.form)
        .then(() => {
          this.toastSucesso(
            this.$t('modulos.colaborador.cadastro_ausencia_sucesso')
          );
          this.$refs['modal-nova-ausencia'].fecharModal();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.$emit('atualizarListagem');
        });
    },
  },
};
</script>
